@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);
.Tile {
	transition: all 0.3s ease;
	background-color:  #DDD;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.Tile:hover {
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
	cursor: pointer;
	-webkit-transform: translateY(-5px);
	        transform: translateY(-5px);
}

.Tile:hover .Tile__icon {
	-webkit-transform: scale(1.3);
	        transform: scale(1.3);
}

.Tile__icon {
	text-align: center;
	margin-bottom: 10px;
	transition: all 0.5s ease;
}

.Tile__name {
	text-transform: uppercase;
	text-align: center;
	font-family:  MyanmarMN;
	font-size:  22px;
	margin-bottom: 10px;
}

.Tile__body {
	display:  flex;
	flex-direction: column;
	xjustify-content: center;
	/*background-color: rgba(255, 255, 255, 0.75);*/
	margin:  30px 30px 0 30px;
	padding:  20px;
	height:  220px;
}

.Tile__icon_image {
	width:  auto;
	height:  45px;
	margin-top:  10px;
	xborder:  1px solid black;
}

.Tile__description {
	text-align: center;
}



/* Ordering - Blue Theme */

.Tile.Tile--ordering {
	background-image: url(/static/media/bg-ordering.e6fa26a9.svg);
	background-color:  #23582c;
}


/* Lounge - Red Theme */

.Tile.Tile--lounge {
	background-image: url(/static/media/bg-lounge.711edcab.svg);
	background-color:  #f17922;
}


/* Social - Teal Theme */

.Tile.Tile--social {
	background-image: url(/static/media/bg-social.0d8a936e.svg);
	background-color: #eb6752;
}



/* Market - Green Theme */

.Tile.Tile--market {
	background-image: url(/static/media/bg-market.fe30e4c0.svg);
	background-color: #4a9e46;
}

.Tile.Tile--market .Tile__icon_image {
	height:  50px;
}



/* AMI - Orange Theme */

.Tile.Tile--ami {
	background-image: url(/static/media/bg-ami.71633684.svg);
	background-color: #14a5b6;
}




/* Stock - Green Theme */

.Tile.Tile--stock {
	background-image: url(/static/media/bg-stock.fc4f4759.svg);;
	background-color: #df1e4a;
}

.Tile.Tile--stock .Tile__icon_image {
	height:  40px;
}



@media only screen and (min-width: 750px) {

	.Tile {
		margin-bottom:  15px;
	}
}

@media only screen and (min-width: 1260px) {
	.Tile__name {
		font-size:  30px;
	}
}
/* Fonts
-----------------------------------------------*/

@font-face { 
	font-family: 'MyanmarMN';
	src: url(/static/media/MyanmarMN.a2396bee.woff2) format('woff');
}


/* Resetter
-----------------------------------------------*/

* {
	margin:  0;
	padding:  0;
	box-sizing: border-box;
	list-style-type: none;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	transition: all 0.5s ease;
}


/* Body
-----------------------------------------------*/

body {
	font-size:  15px;
	font-family: 'Nunito', sans-serif;
	color: #374442;
	padding-bottom:  80px;
}


/* Headings, Paragraphs and Anchors
-----------------------------------------------*/

h1 {
	text-align: center;
	font-size:  20px;
	font-weight:  normal;
	text-transform: uppercase;
	font-family:  MyanmarMN;
}

p {
	font-size: 14px;
	color: #374442;
	text-align: center;
	margin:  10px 0 20px;
}

a {
	text-decoration: none;
	color:  inherit;
}

.Container {
	max-width:  1440px;
	padding:  0 25px;
	width:  100%;
	margin:  0 auto;
}


.button {
	background-color:  #02A4AE;
	padding:  10px 15px;
	font-weight:  bold;
	border-radius: 4px;
	border:  0;
	color:  #FFF;
	font-family: inherit;
	font-size:  inherit;
	cursor: pointer;
}

.button--text {
	background-color: inherit;
	color:  inherit;
}

.button--delete {
	color:  #BC2354;
}

@media only screen and (min-width: 600px) {

	h1 {
		font-size:  40px;
	}



}

.Tiles {
	display: grid;
	grid-gap: 30px;
	color:white;
}



@media only screen and (min-width: 700px) {

	.Tiles {
		grid-gap:  4%;
		grid-template-columns: 48% 48%;
	}
}

@media only screen and (min-width: 950px) {

	.Tiles {
		margin: 30px auto;
		grid-gap:  2%;
		grid-template-columns: 32% 32% 32%;
	}


}

.Banner {
	display:  flex;
	flex-direction: column;
	justify-content: flex-end;
	height:  300px;
	background-size:  cover;
	background-position: top left;
	margin-bottom:  30px;
}

.Banner__body {
	background-color: rgba(255, 255, 255, 0.85);
	padding:  20px 20px 1px 20px;;
}

@media only screen and (min-width: 700px) {

	.Banner {
		height:  350px;
	}

}

@media only screen and (min-width: 950px) {

	.Banner {
		height:  465px;
		background-position:  top center;
	}

	.Banner--height-shallow {
		height:  350px;	
	}
	
	.Banner p {
		font-size:  20px;
		max-width:  800px;
		margin:  0 auto;
	}

	.Banner__body {
		padding:  20px;
	}
	
}


#stockDeliveryIframe {
    border: none;
    height: 100vh;
    margin-top: 40px;
    width: 100%;
}

.overlay {
	display:  flex;
	justify-content: center;
	position: fixed;
	top: 0; 
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	padding-top:  100px;
}


@media only screen and (min-width: 750px) {

	.overlay {
		align-items: center;
	}

}
.Avatar {
	display:  flex;
	align-items: center;
}

.Avatar__name {
	margin-right:  10px;
}

.Avatar__image {
	display:  flex;
	align-items: center;
	justify-content: center;
	width:  48px;
	height:  48px;
	border-radius: 100px;
	background-size: 100%;
	background-color: #02A4AE;
	background-position: center;
	font-size:  18px;
	font-weight:  700;
	color:  #FFF;
}

.Avatar__image--size-large {
	width:  150px;
	height:  150px;
	font-size:  50px;
}

.ModalWindow {
	position:  fixed;
	right:  0;
	top:  0;
	bottom:  0;
	background-color :#FFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0);
	transition: all 0.5s ease
}

.ModalWindow__header {
	padding: 20px;
}

.ModalWindow__close {
	background-image: url(/static/media/close.34144cfe.svg);
	width:  20px;
	height:  20px;
	cursor: pointer;
}

.ModalWindow__body {
	padding: 20px 60px;
	text-align: center;
}


@media (min-width: 600px) {
	.ModalWindow {
		width: 450px;
	}
}

.Loading {
	display: flex;
	align-items: center;
	color: #777;
	font-weight: 600;
}

.Loading--vertical {
	flex-direction: column;
}

.Loading__spinner {
	background-image: url(/static/media/spinner.26077d4d.svg);
	width: 50px;
	height: 50px;
	background-size: cover;
	margin-right: 10px;
}

.Loading--vertical .Loading__spinner {
	margin-right:  0;
}

.ChangePhoto__avatar .Avatar {
	display:  flex;
	justify-content: center;
	margin-bottom:  20px;
}

.ChangePhoto__successful {
	font-size:  20px;
}

.ChangePhoto__successful__icon {
	background-image:  url(/static/media/check.9acf92c8.svg);
	background-size:  50px;
	background-position: center;
	background-repeat: no-repeat;
	height:  50px;
	margin-bottom:  20px;
}
.ChangePassword__result {
	margin-bottom:  20px;
	border-radius: 2px;
	color:  #FFF;
	padding:  10px;
	font-weight:  600;
}

.ChangePassword__result--success {
	background-color:  #76B82A;
}

.ChangePassword__result--error {
	background-color:  #BC2354;
}

.UserProfileScreen * {
	transition: none;
}
.UserProfileScreen {
	text-align: center;
}

.UserProfileScreen__form {
	background-color: #EFF6F6;
	padding:  20px;
	border-radius: 4px;
}

.UserProfileScreen__form__input {
	border:  1px solid #C2DADA;
	padding:  10px;
	width:  100%;
	background-color:  #FFF;
	border-radius: 4px;
	font-family:  inherit;
	font-size:  inherit;
}

.UserProfileScreen__form__input--bottommargin {
	margin-bottom:  5px;
}

.UserProfileScreen__avatar .Avatar {
	display:  flex;
	justify-content: center;
}

.UserProfileScreen__form__description {
	margin-bottom:  20px;
}

.UserProfileScreen__form__error {
	color:  #FF0000;
	margin:  10px 0;
}


.UserProfileScreen__title {
	font-family: MyanmarMN;
	font-size:  20px;
	padding:  20px;
}

.UserProfileScreen__title--large {
	font-size:  30px;
}



.UserProfileScreen__info {
	margin-bottom:  30px;
}

.UserProfileScreen__info__name {
	font-size:  16px;
	opacity:  0.75;
	margin-bottom:  5px;
}

.UserProfileScreen__info__value {
	font-size:  22px;
	font-weight:  bold;
}

.UserProfileScreen__action {
	color:  #02A4AE;
	font-weight:  bold;
	border:  0;
	font-family: inherit;
	font-size:  inherit;
	background-color:  #FFF;
	cursor: pointer;
}



.UserProfileScreen__back {
	cursor: pointer;
	background-image:  url(/static/media/back.80df28df.svg);
	background-repeat: no-repeat;
	background-position: center;
	height:  20px;
	margin-bottom:  20px;
}
.Header {
	display: flex;
	align-items: center;
	padding: 15px 20px !important;
}

.Header__logo {
	flex:  1 1;
	line-height: 0;
}

.Header__logo__img {
	width:  110px;
}

.Header__user {
	cursor:  pointer;
}




@media only screen and (min-width: 600px) {

	


}

.FullScreenLoading {
	position:  fixed;
	top:  0;
	bottom:  0;
	left:  0;
	right:  0;
	background-image:  url(/static/media/spinner.8d51aff2.svg);
	background-repeat: no-repeat;
	background-position: center;
}
.FullScreenError {
	display:  flex;
	align-items: center;
	justify-content: center;
	position:  absolute;
	top:  0; bottom:  0;
	left:  0; right:  0;
	text-align: center;
}

.FullScreenError__title {
	font-size:  30px;
	margin-bottom:  20px;
	font-weight:  700;
}

.FullScreenError__icon {
	width:  150px;
	height:  150px;
	background-size: cover;
	margin:  0 auto 0;
	background-repeat: no-repeat;
}

.FullScreenError__icon--access-denied {
	background-image:  url(/static/media/access-denied.1adef6f3.svg);
}

.FullScreenError__icon--offline {
	background-image:  url(/static/media/offline.f3fb4ce1.svg);
	width:  180px;
	height:  180px;
}

.FullScreenError__message {
	font-size:  22px;
}
