.Header {
	display: flex;
	align-items: center;
	padding: 15px 20px !important;
}

.Header__logo {
	flex:  1;
	line-height: 0;
}

.Header__logo__img {
	width:  110px;
}

.Header__user {
	cursor:  pointer;
}




@media only screen and (min-width: 600px) {

	


}