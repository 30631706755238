.FullScreenError {
	display:  flex;
	align-items: center;
	justify-content: center;
	position:  absolute;
	top:  0; bottom:  0;
	left:  0; right:  0;
	text-align: center;
}

.FullScreenError__title {
	font-size:  30px;
	margin-bottom:  20px;
	font-weight:  700;
}

.FullScreenError__icon {
	width:  150px;
	height:  150px;
	background-size: cover;
	margin:  0 auto 0;
	background-repeat: no-repeat;
}

.FullScreenError__icon--access-denied {
	background-image:  url('access-denied.svg');
}

.FullScreenError__icon--offline {
	background-image:  url('offline.svg');
	width:  180px;
	height:  180px;
}

.FullScreenError__message {
	font-size:  22px;
}