
.Loading {
	display: flex;
	align-items: center;
	color: #777;
	font-weight: 600;
}

.Loading--vertical {
	flex-direction: column;
}

.Loading__spinner {
	background-image: url('spinner.svg');
	width: 50px;
	height: 50px;
	background-size: cover;
	margin-right: 10px;
}

.Loading--vertical .Loading__spinner {
	margin-right:  0;
}