.ChangePassword__result {
	margin-bottom:  20px;
	border-radius: 2px;
	color:  #FFF;
	padding:  10px;
	font-weight:  600;
}

.ChangePassword__result--success {
	background-color:  #76B82A;
}

.ChangePassword__result--error {
	background-color:  #BC2354;
}