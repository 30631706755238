
.Tiles {
	display: grid;
	grid-gap: 30px;
	color:white;
}



@media only screen and (min-width: 700px) {

	.Tiles {
		grid-gap:  4%;
		grid-template-columns: 48% 48%;
	}
}

@media only screen and (min-width: 950px) {

	.Tiles {
		margin: 30px auto;
		grid-gap:  2%;
		grid-template-columns: 32% 32% 32%;
	}


}