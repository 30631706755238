
.ModalWindow {
	position:  fixed;
	right:  0;
	top:  0;
	bottom:  0;
	background-color :#FFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0);
	transition: all 0.5s ease
}

.ModalWindow__header {
	padding: 20px;
}

.ModalWindow__close {
	background-image: url('assets/close.svg');
	width:  20px;
	height:  20px;
	cursor: pointer;
}

.ModalWindow__body {
	padding: 20px 60px;
	text-align: center;
}


@media (min-width: 600px) {
	.ModalWindow {
		width: 450px;
	}
}