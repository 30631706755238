
.UserProfileScreen * {
	transition: none;
}
.UserProfileScreen {
	text-align: center;
}

.UserProfileScreen__form {
	background-color: #EFF6F6;
	padding:  20px;
	border-radius: 4px;
}

.UserProfileScreen__form__input {
	border:  1px solid #C2DADA;
	padding:  10px;
	width:  100%;
	background-color:  #FFF;
	border-radius: 4px;
	font-family:  inherit;
	font-size:  inherit;
}

.UserProfileScreen__form__input--bottommargin {
	margin-bottom:  5px;
}

.UserProfileScreen__avatar .Avatar {
	display:  flex;
	justify-content: center;
}

.UserProfileScreen__form__description {
	margin-bottom:  20px;
}

.UserProfileScreen__form__error {
	color:  #FF0000;
	margin:  10px 0;
}


.UserProfileScreen__title {
	font-family: MyanmarMN;
	font-size:  20px;
	padding:  20px;
}

.UserProfileScreen__title--large {
	font-size:  30px;
}



.UserProfileScreen__info {
	margin-bottom:  30px;
}

.UserProfileScreen__info__name {
	font-size:  16px;
	opacity:  0.75;
	margin-bottom:  5px;
}

.UserProfileScreen__info__value {
	font-size:  22px;
	font-weight:  bold;
}

.UserProfileScreen__action {
	color:  #02A4AE;
	font-weight:  bold;
	border:  0;
	font-family: inherit;
	font-size:  inherit;
	background-color:  #FFF;
	cursor: pointer;
}



.UserProfileScreen__back {
	cursor: pointer;
	background-image:  url('assets/back.svg');
	background-repeat: no-repeat;
	background-position: center;
	height:  20px;
	margin-bottom:  20px;
}