
.ChangePhoto__avatar .Avatar {
	display:  flex;
	justify-content: center;
	margin-bottom:  20px;
}

.ChangePhoto__successful {
	font-size:  20px;
}

.ChangePhoto__successful__icon {
	background-image:  url('assets/check.svg');
	background-size:  50px;
	background-position: center;
	background-repeat: no-repeat;
	height:  50px;
	margin-bottom:  20px;
}