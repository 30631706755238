
.overlay {
	display:  flex;
	justify-content: center;
	position: fixed;
	top: 0; 
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	padding-top:  100px;
}


@media only screen and (min-width: 750px) {

	.overlay {
		align-items: center;
	}

}