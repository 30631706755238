
.Banner {
	display:  flex;
	flex-direction: column;
	justify-content: flex-end;
	height:  300px;
	background-size:  cover;
	background-position: top left;
	margin-bottom:  30px;
}

.Banner__body {
	background-color: rgba(255, 255, 255, 0.85);
	padding:  20px 20px 1px 20px;;
}

@media only screen and (min-width: 700px) {

	.Banner {
		height:  350px;
	}

}

@media only screen and (min-width: 950px) {

	.Banner {
		height:  465px;
		background-position:  top center;
	}

	.Banner--height-shallow {
		height:  350px;	
	}
	
	.Banner p {
		font-size:  20px;
		max-width:  800px;
		margin:  0 auto;
	}

	.Banner__body {
		padding:  20px;
	}
	
}

